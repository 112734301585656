import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./featured-section.scss"

function FeaturedSection() {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { order: DESC, fields: date }, limit: 5) {
        edges {
          node {
            id
            uri
            title
            excerpt
            slug
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 50) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="latest-posts">
      {data.allWpPost.edges.map(({ node }) => (
        <div className="latest-posts--item" key={node.id}>
          <Link to={node.slug}>
            <div className="latest-posts--item-image">
              <Img
                fluid={node.featuredImage.node.localFile.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            <div className="latest-posts--item-content">
              <ul className="latest-posts--item-meta">
                {node.categories.nodes.map(category => (
                  <li key={category.slug}> {category.name} </li>
                ))}
              </ul>
              <h2
                dangerouslySetInnerHTML={{
                  __html: node.title,
                }}
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default FeaturedSection
