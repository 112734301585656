import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "./FourGrid.scss"

function FourGrid(props) {
  const data = useStaticQuery(graphql`
    query {
      # posts(where: { categoryName: "local" }, first: 7) {
      allWpPost(
        sort: { order: DESC, fields: date }
        limit: 4
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Cinema" } } } }
        }
      ) {
        edges {
          node {
            id
            uri
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="four-grid-wrap">
      <h3>Cinema</h3>
      <div className="four-grid">
        {data.allWpPost.edges.map(({ node }) => {

        const date = new Date(node.date)

        const dateTimeFormat = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        })
        const [
            { value: month },
            ,
            { value: day },
        ] = dateTimeFormat.formatToParts(date)
          
          return (
            <div className="four-grid--item" key={node.id}>
              <Link to={node.slug}>
                <div className="four-grid--item-image">
                  <Img
                    fluid={
                      node.featuredImage.node.localFile.childImageSharp.fluid
                    }
                  />
                </div>
                <div className="four-grid--item-content">
                  <span className="cards-content--from_now">{month} {day}</span>
                  <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FourGrid
