import React, {useEffect} from "react"

import "./banner.scss"


function HomeBanner() {


  const useScript = url => {
    useEffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = false;

      document.body.appendChild(script);
  
      return () => {
        // document.body.removeChild(script);

      }
    }, [url]);
  };
  
  
  return (
    <div className="banner">
        {useScript('//euphona.xyz/adservexv/www/delivery/asyncjs.php')}
    
    <ins data-revive-zoneid="1" data-revive-id="b0482e13affac0e362d1916782c9e385"></ins>
    </div>
  )
}

export default HomeBanner
