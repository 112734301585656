import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import './dbs.scss'

function DoubleSide(props) {
  const data = useStaticQuery(graphql`
    query {
      # posts(where: { categoryName: "local" }, first: 7) {
      allWpPost(
        sort: { order: DESC, fields: date }
        limit: 4
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Politics" } } } }
        }
      ) {
        edges {
          node {
            id
            uri
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    
  return (
    <div className="double-side">
      <h3>Politics</h3>
      <div className="double-side-grid">
        {data.allWpPost.edges.map(({ node }) => {
        
        const date = new Date(node.date)

        const dateTimeFormat = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        })
        const [
            { value: month },
            ,
            { value: day },
        ] = dateTimeFormat.formatToParts(date)

        return (
          <div className="double-side--item" key={node.id}>
            <Link to={node.slug}>
              <div className="double-side--item-image">
                <Img
                  fluid={
                    node.featuredImage.node.localFile.childImageSharp.fluid
                  }
                />
              </div>
              <div className="double-side--item-content">
                <span className="double-side-meta">{month} {day}</span>
                <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
              </div>
            </Link>
          </div>
        )
}
        )}
      </div>
    </div>
  )
}

export default DoubleSide
