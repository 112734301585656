import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import "./TallGrid.scss"

function TallGrid(props) {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        sort: { order: DESC, fields: date }
        limit: 4
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Gulf" } } } }
        }
      ) {
        edges {
          node {
            id
            uri
            title
            excerpt
            date
            slug
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="tall-grid-wrap">
      <h3>Gulf News</h3>
      <div className="tall-grid">
        {data.allWpPost.edges.map(({ node }) => {

          const date = new Date(node.date)

          const dateTimeFormat = new Intl.DateTimeFormat("en", {
              year: "numeric",
              month: "short",
              day: "2-digit",
          })
          const [
              { value: month },
              ,
              { value: day },
              ,
              // { value: year },
          ] = dateTimeFormat.formatToParts(date)

          return (
            <div className="tall-grid--item" key={node.id}>
              <Link to={node.slug}>
                <BackgroundImage
                  Tag="div"
                  className="tall-grid--item-image"
                  fluid={node.featuredImage.node.localFile.childImageSharp.fluid}
                >
                </BackgroundImage>
                <div className="tall-grid--item-content">
          <span className="date">{month} {day}</span>
                  <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TallGrid
