import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import LatestLogo from "./logo-file.svg"
import "./Editorial.scss"

function Editorial(props) {
  const data = useStaticQuery(graphql`
    query {
      # posts(where: { categoryName: "local" }, first: 7) {
      allWpPost(
        sort: { order: DESC, fields: date }
        limit: 2
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Editorial" } } } }
        }
      ) {
        edges {
          node {
            id
            uri
            title
            excerpt
            slug
            date(fromNow: true)
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="editorial">
    <img className="editorial-logo" src={LatestLogo} alt="Latest"/>
      <h3>Editorial</h3>
      <div className="editorial-grid">
        {data.allWpPost.edges.map(({ node }) => (
          <div className="editorial--item" key={node.id}>
            <Link to={node.slug}>
              <div className="editorial--item-image">
                <Img
                  fluid={
                    node.featuredImage.node.localFile.childImageSharp.fluid
                  }
                />
              </div>
              <div className="editorial--item-content">
                {/* <span className="cards-content--from_now">{node.date}</span> */}
                <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Editorial
