import { graphql } from "gatsby"
import React, {useEffect} from "react"
import Layout from "../components/layout"
import Banner from "../components/page-components/banner/banner"
import HomeBanner from "../components/page-components/banner/home-page"
import FeaturedSection from "../components/page-components/featured-section"
import DoubleSide from "../components/page-components/grids/DoubleSide/DoubleSide"
import Editorial from "../components/page-components/grids/Editorial/Editorial"
import Features from "../components/page-components/grids/FourGrid/Features"
import FourGrid from "../components/page-components/grids/FourGrid/FourGrid"
import SevenGrid from "../components/page-components/grids/SevenGrid/SevenGrid"
import TallGrid from "../components/page-components/grids/TallGrid/TallGrid"
import Seo from "../components/partials/seo"

const Homepage = ({data}) => {

  return ( 
    <Layout className="wrap">
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc}/>
          <div className="container">
            <div style={{marginTop: -30}}>
              <HomeBanner />
            </div>
            <FeaturedSection />
            <SevenGrid />
            <HomeBanner />
            <DoubleSide />
            <TallGrid />
            <Editorial/>
            <HomeBanner />
            <FourGrid/>
            <Features />
          </div>
        </Layout>
   );
}
 
export default Homepage

export const HomeProjectsQuery = graphql`
  query HomePageQuery {
    wpPage(uri: {eq: "/"}) {
    title
    uri
    slug
    featuredImage {
      node {
        localFile {
          absolutePath
        }
      }
    }
    seo {
      metaKeywords
      metaDesc
      title
      schema {
        pageType
      }
      twitterDescription
      twitterTitle
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphSiteName
      opengraphPublisher
    }
  }
  }
`
